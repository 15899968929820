import Offcanvas from "react-bootstrap/Offcanvas";
import Variables from "../../Utils/Variables";
import Map from "../../Map/Map";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteFillup } from "./../../API/FillupApi";
import { Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useContext } from "react";
import AppContext from "../../auth/AppContext";

function FillupOffCanvas({ showFillupDetails, setShowFillupDetails, rowData }) {
  const queryClient = useQueryClient();
  const handleClose = () => setShowFillupDetails(false);

  const { token } = useContext(AppContext);

  // Handles button click
  const handleRemoveFillup = async () => {
    await removeFillup(rowData._id);
  };

  // Deletes the fill up
  const { mutateAsync: removeFillup } = useMutation({
    mutationFn: (fillupId) => deleteFillup(fillupId, token),
    onSuccess: () => {
      toast.success(`Removed fillup`, {
        theme: "light",
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });

      // Close the overlay
      setShowFillupDetails(false);
      queryClient.invalidateQueries([Variables.FILLUPS]);
    },
  });
  return (
    <>
      <Offcanvas show={showFillupDetails} onHide={handleClose}>
        <Offcanvas.Header closeButton closeVariant={"white"}>
          <Offcanvas.Title>
            Fill up on{" "}
            {new Date(rowData.createdOn).toLocaleString(
              "us-en",
              Variables.DATE_OPTIONS
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table bordered={false}>
            <tbody>
              <tr>
                <td>
                  <b>Mileage:</b>
                </td>
                <td>{rowData.miles.toLocaleString()}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>
                  <b>Cost per gallon: </b>
                </td>
                <td>${rowData.costPerGallon.toFixed(3)}</td>
              </tr>
              <tr>
                <td>
                  <b>Total gallons:</b>
                </td>
                <td>{rowData.gallons}</td>
              </tr>
              <tr>
                <td>
                  <b>Total cost:</b>
                </td>
                <td>${rowData.cost.toFixed(2)}</td>
              </tr>
              <tr>
                <td>
                  <b>Trip distance:</b>
                </td>
                <td>{rowData.tripDistance} mi</td>
              </tr>
              <tr>
                <td>
                  <b>Trip MPG:</b>
                </td>
                <td>{rowData.tripMpg?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>
                  <b>Partial fill:</b>
                </td>
                <td>{rowData.partialFill ? "Yes" : "No"}</td>
              </tr>

              <tr>
                <td>
                  <b>Towing:</b>
                </td>
                <td>{rowData.towing ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  <b>Note:</b>
                </td>
                <td>{rowData.note}</td>
              </tr>
              <tr>
                <td>
                  <b>Location:</b>
                </td>
                <td>
                  {rowData.location[0].name}{" "}
                  {rowData.location[0].address &&
                    `- ${rowData.location[0].address}`}
                </td>
              </tr>
            </tbody>
          </Table>

          {rowData?.location[0].lat && rowData?.location[0].lon && (
            <Map lat={rowData.location[0].lat} lon={rowData.location[0].lon} />
          )}
          <div className="pt-2 text-center">
            <button className="btn-custom" onClick={handleRemoveFillup}>
              Remove Fillup
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <ToastContainer />
    </>
  );
}

export default FillupOffCanvas;
