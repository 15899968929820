import Variables from "../../Utils/Variables";
export const TABLE_OPTIONS = {
  sizePerPage: 5,
  hidePageListOnlyOnePage: true,
  showTotal: true,
  hideSizePerPage: true,
};
export const DEFAULT_COLUMNS = [
    {
      dataField: "createdOn",
      text: "Date",
      sort: true,
      sortCaret: (order, column) => sortDisplay(order, column),
      formatter: (cell) =>
        new Date(cell).toLocaleString("us-en", Variables.DATE_OPTIONS),
    },
    {
      dataField: "vehicle[0].vehicle",
      text: "Vehicle",
      sort: true,
      hidden: true,
      sortCaret: (order, column) => sortDisplay(order, column),
      formatter: (cell) => cell.toLocaleString(),
    },
    {
      dataField: "miles",
      text: "Miles",
      sort: true,
      sortCaret: (order, column) => sortDisplay(order, column),
      formatter: (cell) => cell.toLocaleString(),
    },
    {
      dataField: "costPerGallon",
      text: "Gallon $",
      hidden: true,
      sort: true,
      sortCaret: (order, column) => sortDisplay(order, column),
      formatter: (cell) => `$${cell.toFixed(2)}`,
    },
    {
      dataField: "cost",
      text: "Total $",
      sort: true,
      sortCaret: (order, column) => sortDisplay(order, column),
      formatter: (cell) => `$${cell.toFixed(2)}`,
    },
    {
      dataField: "location[0].name",
      text: "Location",
      sort: true,
      sortCaret: (order, column) => sortDisplay(order, column),
    },
  ];

  const sortDisplay = (order, column) => {
    if (!order)
      return (
        <span>
          &nbsp;&nbsp;{"\u2191"}
          {"\u2193"}
        </span>
      );
    else if (order === "asc")
      return (
        <span>
          &nbsp;&nbsp;{"\u2191"}
          <font color="red">{"\u2193"}</font>
        </span>
      );
    else if (order === "desc")
      return (
        <span>
          &nbsp;&nbsp;<font color="red">{"\u2191"}</font>
          {"\u2193"}
        </span>
      );
    return null;
  };
