import Offcanvas from "react-bootstrap/Offcanvas";
import "./offcanvas.css";
import Variables from "../../Utils/Variables";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getVehicle,
  toggleVehicleStatus,
  deleteVehicle,
} from "../../API/VehiclesApi";
import { deleteAllFillupsForVehicle } from "../../API/FillupApi";
import Loading from "./../../Loading/Loading";
import { Table } from "react-bootstrap";
import { useContext } from "react";
import AppContext from "../../auth/AppContext";

function VehicleOffCanvas({
  setDisplayVehicleDetails,
  displayVehicleDetails,
  selectedVehicleId,
}) {
  const { token } = useContext(AppContext);

  const queryClient = useQueryClient();

  // This handles closing the off canvas
  const handleClose = () => setDisplayVehicleDetails(false);

  // Handle toggle vehicle status button
  const handleVehicleStatusChange = async () => {
    toast.info(`${vehicle.make} ${vehicle.model} being updated`, {
      theme: "light",
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
    await toggleStatus();
    toast.dismiss();

    //let the user know
    toast.success(`${vehicle.make} ${vehicle.model} updated`, {
      theme: "light",
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // Handle Deleting vehicle and all fill ups
  const handleDeleteVehicle = async () => {
    const userConfirmation = window.confirm(
      `Are you sure you want to delete the ${vehicle.make} ${vehicle.model} and all of its fill ups?`
    );
    if (userConfirmation) {
      toast.info(`${vehicle.make} ${vehicle.model} being deleted`, {
        theme: "light",
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
      });
      // Start by deleting the fillups which should then also delete the vehicle. We only do this so we dont delete vehicle until successful delete fill ups
      await deleteAllFillups();

      toast.dismiss();

      //let the user know
      toast.success(`${vehicle.make} ${vehicle.model} deleted`, {
        theme: "light",
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });

      handleClose();
    }
  };

  // Get the vehicle
  const { data: vehicle, isLoading } = useQuery({
    queryKey: [Variables.VEHICLE, selectedVehicleId, token],
    queryFn: () => getVehicle(selectedVehicleId, token),
  });

  // Toggle vehicle status
  const { mutateAsync: toggleStatus, isLoading: isStatusChanging } =
    useMutation({
      mutationFn: () =>
        toggleVehicleStatus(selectedVehicleId, !vehicle.active, token),
      onSuccess: () => {
        queryClient.invalidateQueries([Variables.VEHICLES]);
        queryClient.invalidateQueries([Variables.VEHICLE]);

        // Close the canvas
        handleClose();
      },
    });

  // Delete all fill ups for a vehicle and then delete the vehicle on success
  const { mutateAsync: deleteAllFillups, isLoading: isVehicleDeleting } =
    useMutation({
      mutationFn: () => deleteAllFillupsForVehicle(selectedVehicleId, token),
      onSettled: () => {
        deleteSingleVehicle();
      },
    });

  // Delete a vehicle
  const { mutateAsync: deleteSingleVehicle } = useMutation({
    mutationFn: () => deleteVehicle(selectedVehicleId, token),
    onSuccess: () => {
      queryClient.invalidateQueries([Variables.VEHICLES]);
      queryClient.invalidateQueries([Variables.VEHICLE]);
    },
  });

  return (
    <Offcanvas show={displayVehicleDetails} onHide={handleClose}>
      {isLoading || isVehicleDeleting || isStatusChanging ? (
        <Loading />
      ) : (
        <>
          <Offcanvas.Header closeButton closeVariant={"white"}>
            <Offcanvas.Title>
              {vehicle?.year} {vehicle?.make} {vehicle?.model}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Table bordered={false}>
              <tbody>
                <tr>
                  <td>
                    <b>Description:</b>
                  </td>
                  <td>{vehicle?.desc}</td>
                </tr>
                <tr>
                  <td>
                    <b>VIN:</b>
                  </td>
                  <td>{vehicle?.vin}</td>
                </tr>
                <tr>
                  <td>
                    <b>Active:</b>
                  </td>
                  <td>{vehicle?.active ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </Table>
            <div className="text-center pt-2">
              <button
                onClick={handleVehicleStatusChange}
                className="btn-custom"
              >
                {vehicle?.active ? "Deactive Vehicle" : "Activate Vehicle"}
              </button>{" "}
              <button onClick={handleDeleteVehicle} className="btn-custom">
                Delete Vehicle
              </button>
            </div>
          </Offcanvas.Body>
        </>
      )}
    </Offcanvas>
  );
}

export default VehicleOffCanvas;
