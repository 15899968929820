import { Container } from "react-bootstrap";
import DashboardButtons from "../Components/Dashboard/DashboardButtons";
import { useEffect, useState } from "react";
import MpgCard from "../Components/Dashboard/MpgCard";
import PerGallonCard from "../Components/Dashboard/PerGallonCard";
import TotalCostCard from "../Components/Dashboard/TotalCostCard";
import MpgChart from "../Components/Dashboard/MpgChart";
import TotalMilesCard from "../Components/Dashboard/TotalMilesCard";
import FillupCountCard from "./../Components/Dashboard/FillupCountCard";
import LoadingInApp from "../Loading/LoadingInApp";
import { getVehicleFillups } from "../API/FillupApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import HorizontalLine from "../Components/LookAndFeel/HorizontalLine";
import FillupTable from "../Components/Dashboard/FillupTable";
import Variables from "../Utils/Variables";
import CostPerMileCard from "../Components/Dashboard/CostPerMileCard";
import GoogleMultiPointMap from "../Map/GoogleMultiPointMap";
import BestMpg from "../Components/Dashboard/BestMpg";
import { useContext } from "react";
import AppContext from "../auth/AppContext";
import { isWithinLast30Days } from "../Utils/Dates";
import MilesLast30Days from "../Components/Dashboard/MilesLast30Days";
import CostLast30Days from "../Components/Dashboard/CostLast30Days";

function Dashboard() {
  const [vehicle, setVehicle] = useState("");
  const [showFillupDetails, setShowFillupDetails] = useState(false);

  const { user } = useAuth0();
  const { token } = useContext(AppContext);
  // Get fill ups for a vehicle selected
  const { data, isLoading } = useQuery({
    queryKey: [Variables.FILLUPS, user, vehicle?.vehicleId, token],
    queryFn: () => getVehicleFillups(user, vehicle?.vehicleId, token),
    enabled: !!token && !!user,
  });

  const { fillups, fillStats, fillupCoords } = data || {};

  return (
    <>
      <Container>
        <h3>Dashboard</h3>
      </Container>
      <HorizontalLine />
      <DashboardButtons setVehicleId={setVehicle} />

      {isLoading ? (
        <LoadingInApp />
      ) : (
        <>
          {vehicle && !isLoading && (
            <>
              <div>
                <h4 className="text-center">{vehicle.vehicle}</h4>
                <HorizontalLine />
              </div>
              <div className="row" key="statsRow">
                {fillStats.averageMpg > 0 && (
                  <MpgCard mpg={fillStats.averageMpg} />
                )}
                {fillStats.bestMpg > 0 && (
                  <BestMpg bestMpg={fillStats.bestMpg} />
                )}
                {fillStats.averageGallonCost > 0 && (
                  <PerGallonCard gallon={fillStats.averageGallonCost} />
                )}
                {fillStats.totalCost > 0 && (
                  <TotalCostCard totalCost={fillStats.totalCost} />
                )}
                <FillupCountCard count={fillStats.fillupCount} />
                {fillStats.milesTraveled > 0 && (
                  <TotalMilesCard milesTraveled={fillStats.milesTraveled} />
                )}
                {fillStats.costPerMile > 0 &&
                  isFinite(fillStats.costPerMile) && (
                    <CostPerMileCard costPerMile={fillStats.costPerMile} />
                  )}
                {fillStats.milesLast30Days > 0 && (
                  <MilesLast30Days
                    milesLast30Days={fillStats.milesLast30Days}
                  />
                )}
                {fillStats.costLast30Days > 0 && (
                  <CostLast30Days costLast30Days={fillStats.costLast30Days} />
                )}
              </div>
              <MpgChart
                fillups={fillups}
                setShowFillupDetails={setShowFillupDetails}
                showFillupDetails={showFillupDetails}
              />
            </>
          )}
        </>
      )}
      {vehicle && !isLoading && (
        <>
          <div className="pt-3">
            <FillupTable
              fillups={fillups}
              setShowFillupDetails={setShowFillupDetails}
              vehicle={vehicle.vehicle}
            />
          </div>

          {fillupCoords.length > 0 && (
            <>
              <HorizontalLine />
              <h4 className="text-center">Location - Last 10 fillups</h4>
              <GoogleMultiPointMap markers={fillupCoords} />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Dashboard;
