import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Home from "./Pages/Home";
import { Container } from "react-bootstrap";
import AddVehicle from "./Pages/AddVehicle";
import Header from "./Header/Header";
import WelcomeHeader from "./Header/WelcomeHeader";
import Fillup from "./Pages/Fillup";
import { CallbackPage } from "./auth/callback-page";
import Loading from "./Loading/Loading";
import { AuthenticationGuard } from "./auth/AuthenticationGuard";
import Profile from "./Pages/Profile";
import Footer from "./Footer/Footer";
import Dashboard from "./Pages/Dashboard";
import { GroupGuard } from "./auth/GroupGuard";
import Admin from "./Pages/Admin";
import AppContext from "./auth/AppContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getGroups, setDefaultGroup } from "./API/AuthApi";
import GasPrices from "./Pages/GasPrices";
import { useEffect, useState } from "react";
import useToken from "./hooks/useToken";
import ScrollToTop from "./Utils/ScrollToTop";

function App() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const queryClient = useQueryClient();

  // Gets an access token if we got a user
  const { token, getToken } = useToken();

  useEffect(() => {
    if (isAuthenticated) getToken();
  }, [user]);

  // Get groups assigned to the user
  const { data: groups } = useQuery({
    queryKey: ["authGroups", user, isAuthenticated, token],
    queryFn: () => getGroups(user, isAuthenticated, token),
    refetchInterval: Infinity,
    enabled: !!token,
  });

  // Handles adding a default group
  const { mutateAsync: addGroup } = useMutation({
    mutationFn: () => setDefaultGroup(user, isAuthenticated, token),
    onSuccess: () => {
      console.log("Default group set");
      queryClient.invalidateQueries(["authGroups"]);
    },
    enabled: !!token,
  });

  // When we get a list of groups, check to see if the default is there, if its not add it.
  // At some point it might make more sense to set it to check for length > 1 implying already has a group
  useEffect(() => {
    if (groups) {
      const isFound = groups.find((group) => {
        return group.name === "free-user";
      });
      if (!isFound) {
        addGroup();
      }
    }
  }, [groups]);

  // This prevents displays until auth is done and page is loaded
  if (isLoading) {
    return (
      <div className="page-layout">
        <Loading />
      </div>
    );
  }

  return (
    <AppContext.Provider value={{ groups, token }}>
      <WelcomeHeader />
      <Header />
      <Container>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gasPrices" element={<GasPrices />} />
          <Route
            path="/addVehicle"
            element={<AuthenticationGuard component={AddVehicle} />}
          />
          <Route
            path="/fillup"
            element={<AuthenticationGuard component={Fillup} />}
          />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="*" element={<Home />} />
          <Route
            path="/profile/"
            element={<AuthenticationGuard component={Profile} />}
          />
          <Route
            path="/profile/:isFillup"
            element={<AuthenticationGuard component={Profile} />}
          />
          <Route
            path="/dashboard"
            element={<AuthenticationGuard component={Dashboard} />}
          />
          <Route
            path="/admin"
            element={
              <GroupGuard>
                <AuthenticationGuard component={Admin} />
              </GroupGuard>
            }
          />
        </Routes>
      </Container>
      <Footer />
      <ReactQueryDevtools initialIsOpen={false} />
    </AppContext.Provider>
  );
}

export default App;
