import "./../Components/Fillup/fillup.css";
import "react-toastify/dist/ReactToastify.css";
import Variables from "../Utils/Variables";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Mileage from "./../Components/Fillup/Mileage";
import Gallons from "./../Components/Fillup/Gallons";
import TotalCost from "./../Components/Fillup/TotalCost";
import Note from "./../Components/Fillup/Note";
import VehiclesDropDown from "../Components/Vehicle/VehiclesDropDown";
import { isAutoFillPossible } from "./../Components/Fillup/AutoFill";
import Location from "./../Components/Fillup/Location";
import Buttons from "./../Components/Fillup/Buttons";
import Map from "../Map/Map";
import { ToastContainer, toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import LastFillup from "./../Components/Fillup/LastFillup";
import Price from "./../Components/Fillup/Price";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addFillup, getNumberOfFillups } from "../API/FillupApi";
import { QueryClient } from "@tanstack/react-query";
import LoadingInApp from "../Loading/LoadingInApp";
import { useNavigate } from "react-router-dom";
import PartialFill from "../Components/Fillup/PartialFill";
import MissedFill from "../Components/Fillup/MissedFill";
import { useContext } from "react";
import AppContext from "../auth/AppContext";
import Towing from "../Components/Fillup/Towing";

function Fillup() {
  const queryClient = new QueryClient();
  const [vehicle, setVehicle] = useState("");
  const [isMissedFill, setIsMissedFill] = useState(false);
  const [distanceTraveled, setDistanceTraveled] = useState(0);
  const [miles, setMiles] = useState("");
  const [price, setPrice] = useState("");
  const [gallons, setGallons] = useState("");
  const [towing, setTowing] = useState(false);
  const [totalCost, setTotalCost] = useState("");
  const [note, setNote] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [location, setLocation] = useState();
  const [isPartialFill, setIsPartialFill] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { token } = useContext(AppContext);

  // Handles adding to database
  const { mutateAsync: fillup } = useMutation({
    mutationFn: (fillup) => addFillup(fillup, token),
    onSuccess: () => {
      queryClient.invalidateQueries([Variables.FILLUPS]);
      // After success move along to profile page
      navigate("/profile?isFillup=true");
    },
  });

  // Get the last fill up
  const { data: fillups, isLoading } = useQuery({
    queryKey: [Variables.FILLUPS, user, vehicle.vehicleId, 1, token],
    queryFn: () => getNumberOfFillups(user, vehicle.vehicleId, 1, token),
  });

  const formatTotalCost = (cost) => {
    // This removes decimal if its there
    let value = cost.replace(".", "");

    // Adds decimal as typed
    if (value.length > 2) {
      value = value.slice(0, -2) + "." + value.slice(-2);
    }
    setTotalCost(value);
  };

  // When we lose focus we see if we can auto calculate fields
  const handleFocusOut = () => {
    let auto = isAutoFillPossible(price, gallons, totalCost);

    if (auto) {
      if (price && gallons) {
        setTotalCost((price * gallons).toFixed(2));
      } else if (price && totalCost) {
        setGallons((totalCost / price).toFixed(3));
      } else if (gallons && totalCost) {
        setPrice((totalCost / gallons).toFixed(3));
      }
    }
  };

  // Clears out fields
  const handleClear = () => {
    setPrice("");
    setGallons("");
    setTotalCost("");
    setMiles("");
    setEnableSubmit(false);
    setNote("");
    setTowing(false);
    setIsMissedFill(false);
    setIsPartialFill(false);
  };

  // When add fill up is clicked, put together the request, call API, clear, notify
  const handleAddFill = async (event) => {
    event.preventDefault();
    let mpg = 0;

    // Checks to see if the last fill was partial (assuming there was) and if it is, calculate MPG since last fill
    // TODO: Should really look further back than 1 fill up for partial fill ups
    if (fillups?.[0]?.partialFill ?? false) {
      const totalGallons =
        parseFloat(fillups?.[0]?.gallons) + parseFloat(gallons);
      const totalDistance = fillups?.[0]?.tripDistance + distanceTraveled;
      mpg = (totalDistance / totalGallons).toFixed(2);
    } else {
      mpg = (distanceTraveled / gallons).toFixed(2);
    }

    const newFillUp = {
      gallons: gallons,
      cost: totalCost,
      costPerGallon: price,
      miles: miles,
      partialFill: isPartialFill,
      location: { ...location },
      vehicle: { ...vehicle },
      note: note,
      user: user.sub,
      towing: towing,
      tripDistance: distanceTraveled,
      ...(!isPartialFill &&
        !isMissedFill && {
          tripMpg: mpg,
        }),
    };

    // This is to ensure we dont submit with incomplete data
    if (
      gallons > 0 &&
      totalCost > 0 &&
      price > 0 &&
      vehicle.vehicleId.length > 1 &&
      miles > 0
    ) {
      // Add the fill up to the database
      await fillup(newFillUp);
    } else {
      toast.error(`Please complete all required fields`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // Look for changes to these values and see if we can enable the button
  useEffect(() => {
    // Used for deciding if we enable the add fill up button
    if (miles && price && gallons && vehicle && totalCost) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [miles, price, gallons, vehicle, totalCost]);

  // Sets distance traveled as information files in
  useEffect(() => {
    if (miles && fillups?.length > 0) {
      setDistanceTraveled(miles - fillups[0].miles);
    }
  }, [miles, fillups]);

  return (
    <>
      <Form>
        <Buttons
          enableSubmit={enableSubmit}
          handleAddFill={handleAddFill}
          handleClear={handleClear}
        />

        <h4>Vehicle</h4>
        <VehiclesDropDown setVehicleId={setVehicle} activeOnly={true} />
        {isLoading ? (
          <LoadingInApp />
        ) : (
          fillups?.length > 0 &&
          vehicle.vehicleId && (
            <>
              <h4 className="mt-3">Last Fillup</h4>
              <LastFillup fillup={fillups[0]} />
            </>
          )
        )}

        <h4 className="mt-3">Fill Info</h4>
        <fieldset className="fieldset">
          <Mileage
            setMiles={setMiles}
            miles={miles}
            handleFocusOut={handleFocusOut}
            distanceTraveled={distanceTraveled}
          />
          <Price
            setPrice={setPrice}
            price={price}
            handleFocusOut={handleFocusOut}
          />
          <TotalCost
            formatTotalCost={formatTotalCost}
            totalCost={totalCost}
            handleFocusOut={handleFocusOut}
          />
          <Gallons
            setGallons={setGallons}
            gallons={gallons}
            handleFocusOut={handleFocusOut}
          />

          <PartialFill
            isPartialFill={isPartialFill}
            setIsPartialFill={setIsPartialFill}
          />
          <MissedFill
            isMissedFill={isMissedFill}
            setIsMissedFill={setIsMissedFill}
          />
          <Towing towing={towing} setTowing={setTowing} />

          {distanceTraveled > 0 &&
            gallons &&
            !isPartialFill &&
            !isMissedFill &&
            (fillups[0].partialFill ? (
              <div className="mb-3">
                {(
                  (fillups?.[0]?.tripDistance + distanceTraveled) /
                  (parseFloat(fillups?.[0]?.gallons) + parseFloat(gallons))
                ).toFixed(2)}{" "}
                mpg since last fill-up
              </div>
            ) : (
              <div className="mb-3">
                {(distanceTraveled / gallons).toFixed(2)} mpg over{" "}
                {distanceTraveled} mi
              </div>
            ))}
        </fieldset>
        <Note setNote={setNote} note={note} handleFocusOut={handleFocusOut} />

        <Buttons
          enableSubmit={enableSubmit}
          handleAddFill={handleAddFill}
          handleClear={handleClear}
        />
      </Form>
      <Location setLocation={setLocation} />
      {location?.lat && <Map lat={location.lat} lon={location.lon} />}
      <ToastContainer />
      <div className="copyright"> &copy; TomTom</div>
    </>
  );
}

export default Fillup;
